@import "_common.scss";
@import "_vars.scss";

.key-text {
  display: "inline-block";
  font-size: "14px";
  line-height: "14px";
  @media screen and (max-width: $screen-large) {
    font-size: "10px";
    line-height: "10px";
  }
}
@import "_common.scss";
@import "_vars.scss";

.middle-info-container {
  position: absolute;
  bottom: 60%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px 20px;
  border-radius: 20px;
  width: 25%;
  @media screen and (max-width: $screen-large) {
    left: auto !important;
    right: 5% !important;
    bottom: auto !important;
  }
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter-VariableFont_slnt,wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "Pridi";
  src: url("../public/fonts/Pridi-Regular.ttf") format("truetype");
  font-weight: norrmal;
}

@font-face {
  font-family: "Pridi";
  src: url("../public/fonts/Pridi-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "RobotoMono-Regular";
  src: url("../public/fonts/RobotoMono-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

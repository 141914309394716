@import "_vars.scss";

.table-background {
  background-color: $dark-grey;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: -99;
  overflow: hidden;
}

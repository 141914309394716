$primary: #bcabc3;

$success: #2da44e;
$error: #f00;
$dark-grey: #232323;
$light-grey: #f2f2f2;
$primary: #5e4075;
$card-height: 100px; // also found in ConstantsFE bc couldn't export
$card-width: $card-height * 0.7;
$suit-distance: 5px;

$animation-time: 0.8s;
$screen-large: 1200px;

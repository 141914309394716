@import "_vars.scss";

.player-cards-container {
  display: flex;
  position: absolute;
  bottom:30%;
  left: 50%;
  transform: translateX(-50%);
  margin-left: auto;
  margin-right: auto;
}

.player-cards-flex-container > div {
  margin-left: calc($card-width / -2);
}

.player-cards:first-child{
  margin: 0;
}

.display-none {
  display: none;
}
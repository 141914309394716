.logged-in {
  background-color: #fff;
  border: "1px solid #dadce";
  color: #3c4043;
  border-radius: 4px;
  height: 40px;
  font-size: 12px;
  text-align: left;
  padding: 2px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.logged-in-name {
  font-weight: bold
}

.logged-in-prof-pic {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin-right: 8px;
}
@import "_common.scss";
@import "_vars.scss";

.card-action-container {
  z-index: 100;
  padding: 2%;
  bottom: 0;
  width: 50%;
  @media screen and (max-width: $screen-large) {
    width: 80%;
    padding: 0;
    bottom: 13%;
  }
}

.card-actions {
  display: flex;
  justify-content: space-between;
}

@import "_vars.scss";

.card-action-disabled {
  cursor: default !important;
}

.card-action-button {
  height: 50px;
  width: 110px;
  font-size: 14px;
  @media screen and (max-width: $screen-large) {
    width: 80px;
    height: 40px;
    font-size: 12px !important;
  }
}

.card-action-button-disabled {
  background-color: "#aaa" !important;
  pointer-events: "none" !important;
}

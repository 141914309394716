@import "_vars.scss";

.dealer-container {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  margin-left: auto; 
  margin-right: auto; 
}

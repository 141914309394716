@import "_common.scss";
@import "_vars.scss";

.logo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  // background-image: url("../img/logo.png");
  position: absolute;
  bottom: 18%;
  width: 50vw;
  text-align: center;
  color: #fff;
  @media screen and (max-width: $screen-large) {
    right: 25px !important;
  }
  z-index: 1;
}

.logo-img {
  height: 200px;
  @media screen and (max-width: $screen-large) {
    height: 100px;
  }
}

.table-title {
  font-family: "Pridi";
  font-size: 6em;
  white-space: nowrap;
  margin-left: 25px;
  @media screen and (max-width: $screen-large) {
    font-size: 3em;
  }
}

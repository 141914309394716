@import "_vars.scss";

.decision-container > p{
  margin: 0;
}

.correct-decision {
  text-decoration: underline;
  text-decoration-color: $success;
  text-decoration-thickness: 20%;
  text-underline-position: under;
}

.incorrect-decision {
  text-decoration: underline;
  text-decoration-color: $error;
  text-decoration-thickness: 20%;
  text-underline-position: under;
}
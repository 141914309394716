@import "_vars.scss";

.player-container {
  position: absolute;
  bottom: 30%;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: $card-width;
}

.player-cards-container {
  width: fit-content;
}

.player-cards-flex-container {
  width: fit-content;
  display: flex;
}

#player-container-even-1, #player-container-odd-1 {
  left: 60%;
  bottom: 45%;
}

#player-container-even-2, #player-container-odd-2 {
  left: 30%;
}

#player-container-even-3, #player-container-odd-4 {
  right: 30%;
}

#player-container-even-4, #player-container-odd-5 {
  right: 60%;
  bottom: 45%;
}


@import "_vars.scss";

.dealer-cards-container {
  opacity: 1;
  z-index: 100;
  border-radius: 20%;
  display: flex;
}

.dealer-cards-flex-container {
  display: flex;
  position: relative;
}
.dealer-cards-flex-container > div {
  margin-left: calc($card-width / -2);
}
 
.dealer-cards:first-child{
  margin: 0;
}

.dealer-hidden-card {
  margin: 0 !important;
}
@import "_vars.scss";

.card {
  position: relative;
  justify-content: center;
  width: $card-width;
  height: $card-height;
  border: 2px solid #000;
  background-color: #fff;
  border-radius: 5px;
  @media screen and (max-width: $screen-large) {
    height: 75px;
    width: 50px;
  }
}

.card-animation {
  transform-style: preserve-3d;
}

.card-hidden {
  background-color: #f00;
}

.card-text {
  font-size: 20px;
  line-height: 90%;
  @media screen and (max-width: $screen-large) {
    font-size: 16px;
    line-height: 75%;
  }
}

.heart-text {
  font-size: 14px;
  line-height: 18px;
  @media screen and (max-width: $screen-large) {
    font-size: 12px;
    line-height: 12px;
  }
}

.text-red {
  color: #f00;
}

.top-left {
  position: absolute;
  top: $suit-distance;
  left: 5px;
  font-size: 25px;
  display: flex;
  flex-direction: column;
}

.suit-bottom-right {
  transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -webkit-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
  position: absolute;
  bottom: $suit-distance;
  right: 5px;
  font-size: 25px;
}

.card-front .card-back {
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.begin-rotation {
  transform: rotateY(180deg);
}

/* THE MAINCONTAINER HOLDS EVERYTHING */

/* THE CARD HOLDS THE FRONT AND BACK FACES */
.card-animation-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all $animation-time ease;
}

/* THE FRONT FACE OF THE CARD, WHICH SHOWS BY DEFAULT */
.card-front,
.card-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  overflow: hidden;
}

/* THE BACK FACE OF THE CARD, WHICH SHOWS ON MOUSEOVER */
.card-front {
  transform: rotateY(180deg);
}

.display-none {
  display: none;
}

@import "_vars.scss";

.modal {
  background-color: $light-grey;
}

.modal-header {
  background-color: $dark-grey;
  color: $light-grey;
  border-radius: 20px 0 0 0;
  padding: 16px;
  margin-top: -1px;
  @media screen and (max-width: $screen-large) {
    border-radius: 0;
  }
}

.modal-body {
  padding: 8px;
}

.shortcut {
  font-family: "RobotoMono-Regular";
  padding: 0.2em 0.4em;
  background-color: $dark-grey;
  color: $light-grey;
  margin-right: 0.2em;
}

.shortcut-space {
  font-weight: bold;
}

.chart-help-img {
  border-radius: 20px;
}

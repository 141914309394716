.count-label {
  font-size: 1em;
  margin-top: 0.75em;
  line-height: 0.25em;
}

.count {
  font-size: 2em;
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .count-label {
    font-size: 0.7em;
  }

  .count {
    font-size: 1.5em;
  }
}
